import { Settings } from "../../app/modules/shared/interfaces/settings";

export const environment: Settings = {
  isProduction: false,
  dataProtectionUrl: 'https://fuxx-sparenergie.de/wp-content/uploads/Fuxx_Web_Datenschutz_EB.pdf',
  dataProtectionWebUrl: 'https://fuxx-sparenergie.de/datenschutz/',
  legalNoticeUrl: 'https://fuxx-sparenergie.de/rechtliches/impressum/',
  homePageUrl: 'https://fuxx-sparenergie.de/',
  faqUrl: 'https://fuxx-sparenergie.de/kundenservice/faq/',
  membershipApplicationFormUrl: 'https://fuxx-sparenergie.de/wp-content/uploads/Fuxx_Vertragspartnerdaten.pdf',
  electricityLabelingUrl: 'https://fuxx-sparenergie.de/energiewelt/stromkennzeichnung/',
  copyrightBy: 'Fuxx-Die Sparenergie GmbH',
  hotline: '040 658 490 90',
  brandName: 'Fuxx Sparenergie',
  brandEmail: 'service@fuxx-sparenergie.de',
  customerPortalUrl: 'https://kundenportal.fuxx-sparenergie.de/',
  dashboardUrl: 'dashboard',
  moveUrl: 'move',
  moveSuccessUrl: 'success',
  loginUrl: 'login',
  magicKeyUrl: 'magic-key',
  magicKeyCreateUrl: 'create',
  meterReadingUrl: 'meter-reading',
  monthlyPaymentUrl: 'monthly-payment',
  contactFormUrl: 'contact',
  contractOverviewUrl: 'contract-overview',
  contactMessageSent: 'success',
  paymentInformationUrl: 'payment-information',
  documentsUrl: 'documents',
  orderCorrectionUrl : 'order-correction',
  minimumLoadingTimeInMilliseconds: 400,
  minimumProgressTimeInMilliseconds: 800,
  maximumFileLengthInBytes: 5242880,
   acceptedFileUploadExtensions: ['.png', '.jpg', '.jpeg', '.pdf'],
  creditSettlementUrl: 'credit-settlement',
  showWaiveBalance: false,
  tariffUrl: 'tariff',
  mustSelectPayoutMethodForEachCreditSettlement: true,
  accountStatementUrl: 'account-statement',
  isContractOverviewCardVisible: true,
  isMoveCardVisible: true,
  isMeterReadingCardVisible: true,
  isMonthlyPaymentCardVisible: true,
  isPaymentInformationCardVisible: true,
  isDocumentsCardVisible: true,
  isCreditSettlementCardVisible: true,
  isAccountStatementCardVisible: true,
  isContactCardVisible: true,
  isExternalContactFormUrl: false,
  canCorrectOrder: true,
  showDayaheadPriceOverviewInLogin: false,
  dayaheadPriceOverviewCountry: '#',
  hasOtherPriceComponents: false,
};
